import styled from 'styled-components';
import {$lightGrey, $popularGrey} from '@shelf/colors';

export const Wrapper = styled.div`
  position: relative;
`;

export const InputWrapper = styled.div`
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  padding: 0 15px;
  border: none;
  outline: none;
  box-sizing: border-box;
`;

export const IconHolder = styled.div`
  padding: 0 5px;
  cursor: pointer;
`;

export const Dropdown = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  border-bottom: 1px solid ${$popularGrey};
`;

export const Option = styled.li`
  box-sizing: border-box;
  padding: 10px 15px;
  border-top: 1px solid ${$popularGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: ${$lightGrey};
  }
`;
